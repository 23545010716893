import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from '@aws-amplify/core';
import { ThemeProvider } from '@material-ui/core';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import themes from './themes/Material-UI';

const { cognito } = config;

Amplify.configure({
  Auth: cognito,
});

ReactDOM.render(
  <ThemeProvider theme={themes}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
