import { poolClient, poolId } from './params';

const cognitoConfig = {
  // Amazon Cognito Region
  region: process.env.REACT_APP_REGION,
  // Amazon Cognito User Pool ID
  userPoolId: poolId,
  // Amazon Cognito Web Client ID
  userPoolWebClientId: poolClient,
  mandatorySignIn: true,
};

const config = {
  cognito: cognitoConfig,
  APP_URL: process.env.REACT_APP_URL,
  // API
  API: process.env.REACT_APP_API,
};

export default {
  ...config,
};
