import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Layout from './Layout';
import withAuth from './hoc/withAuth';
import routes from './routes.js';
import config from './config';

import './App.css';

const { cognito: {authEnabled} } = config;

function App() {
  const loading = () => <CircularProgress/>;

  return (
    <Layout>
      <Suspense fallback={loading()}>
        <Switch>
          {routes.map((route, idx) => (route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(newProps) => (
                <route.component
                  {...newProps}
                  name={route.name}
                />
              )}
            />
          ) : (null)))}
          <Redirect from="/" to="/dashboard"/>
        </Switch>
      </Suspense>  
    </Layout>
  );
}
console.log('authenabled', authEnabled);
const appExport = withAuth(App); 

export default appExport;
