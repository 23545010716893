import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { NavBar } from '../Components/Nav';
import { SideBar } from '../Components/Nav';

function Layout({ title, children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  return (
    <BrowserRouter>
      <NavBar title={title}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        isSidebarOpen={isSidebarOpen}
        signout={() => Auth.signOut()}>
      </NavBar>
      {children}
      <SideBar
        isSidebarOpen={isSidebarOpen}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
      ></SideBar>
    </BrowserRouter>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;