import React from 'react';
import { Home, Settings } from '@material-ui/icons';

const NavLinks = {
  sideNav: [
    {
      name: "Home",
      url: "/dashboard",
      icon: <Home />,
    },
    {
      name: "Settings",
      url: "/settings",
      icon: <Settings />,
    },
  ]
};

export default NavLinks;