import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, IconButton, makeStyles, Typography, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon, ExitToApp as ExitIcon } from '@material-ui/icons';
import { Tooltip, Box } from '@mui/material';
import logo from '../../images/TrustAlice Wordmark White-33.svg';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  signOutButton: {
    justifyContent: "flex-end",
    flexGrow: 1,
  },
  toolbar: {
    flexGrow: 1,
    display: 'none',
    [
      theme.breakpoints.up('sm')]: {
        display: 'block',
    },
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      zIndex: 2,
      background: '#fff',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    flexGrow: 1,
  },
  logo: {
    height: 30,
    flexGrow: 1,
    display: 'none',
    [
      theme.breakpoints.up('sm')]: {
        display: 'block',
    },
  },
}));

function NavBar({ title, openSidebar, closeSidebar, signout }) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
    <AppBar position="sticky">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuButton}
          onClick={() => openSidebar()}>
          <MenuIcon />
        </IconButton>
        <img src={logo} alt='' className={classes.logo} noWrap />
        {/* <Typography variant="h3" noWrap className={classes.toolbar}> 
            Trust Alice
         </Typography> */}
        <Tooltip title={'Sign Out'} enterDelay={300}>
        <IconButton onClick={signout} color='inherit'>
          <ExitIcon />
        </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
    </div>
  );
}

NavBar.propTypes = {
  signout: PropTypes.func,
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  title: PropTypes.string,
};

export default NavBar;