import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NavLinks from './NavLinks';

const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: 250,
  },
  sideItems: {
    color: '#0080b7',
  },
}));

function SideBar({ isSidebarOpen, closeSidebar, openSidebar }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <SwipeableDrawer
      open={isSidebarOpen}
      onClose={closeSidebar}
      onOpen={openSidebar}
      className={classes.sideBar}
    >
      <List>
        {NavLinks.sideNav.map((item, index) => {
          return (
            <ListItem button
              key={index}
              onClick={() => history.push(item.url)}
              className={classes.sideBar}

            >
              <ListItemIcon className={classes.sideItems}>{item.icon}</ListItemIcon>
              <ListItemText className={classes.sideItems} primary={item.name} />
            </ListItem>
          );
        })}
      </List>
    </SwipeableDrawer>
  )
}

SideBar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
};


export default SideBar;